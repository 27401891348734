import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

const usePositions = () => {
  return useQuery("/Positions", () => axios(`${baseUrl}/positions`), {
    refetchInterval: 30000,
    refetchOnWindowFocus: false,
  });
};

const useGroupedPositions = () =>
  useQuery(
    "/Positions/stat",
    async () => (await axios(`${baseUrl}/positions/stat`)).data,
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
    },
  );

const useStateInvestments = () =>
  useQuery(
    "/Investment/stats",
    async () => (await axios(`${baseUrl}/stats/investments`)).data,
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
    },
  );

export { useGroupedPositions, useStateInvestments };
export default usePositions;
