import { createSlice } from "@reduxjs/toolkit";

export type TablePagination = {
  tabs: {
    tab0: { current: number; size: number };
    tab1: { current: number; size: number };
    tab2: { current: number; size: number };
    tab3: { current: number; size: number };
  };
};

const initialState: TablePagination = {
  tabs: {
    tab0: {
      current: 1,
      size: 5,
    },
    tab1: {
      current: 1,
      size: 5,
    },
    tab2: {
      current: 1,
      size: 5,
    },
    tab3: {
      current: 1,
      size: 5,
    },
  },
};

const slice = createSlice({
  name: "tablepagination",
  initialState,
  reducers: {
    setTablePagination: (state: TablePagination, action) => {
      state.tabs = action.payload;
    },
  },
});

export const { setTablePagination } = slice.actions;

export default slice.reducer;
