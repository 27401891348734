import React, { useEffect, Suspense, lazy, useMemo } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PrivateRoute from "src/utils/PrivateRoute";
import LogOut from "./utils/LogOut";
import { loadUser } from "src/store/Client";
import * as ROUTES from "./utils/constants/routes";
import Loading from "src/components/Loading";
import HeaderItems from "src/components/HeaderItems";
import useClient from "src/store/hooks/useClient";
import FinaLayout from "./components/FinaLayout";
import TagManager from 'react-gtm-module';
import { useInvestments } from "src/store/hooks";
import { useAnalytics } from "./hooks/useAnalytics";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Withdraw = lazy(() => import("./pages/Withdraw"));
const LeanDeposit = lazy(() => import("./pages/LeanDeposit"));
const Settings = lazy(() => import("./pages/Settings"));
const TempLogin = lazy(() => import("./pages/TempLogin"));
const Invest = lazy(() => import("./pages/Invest"));
const OurSmartfolios = lazy(() => import("./pages/OurSmartfolios"));
const InvestmentDetails = lazy(() => import("./pages/InvestmentDetails"));
const NotFound = lazy(() => import("./pages/Error/NotFound"));
const Error = lazy(() => import("./pages/Error"));

const App: React.FC = () => {
  useAnalytics();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const selectedSmartfolio: string | undefined = useMemo(
    () => location.state?.name,
    [location.state],
  );

  const CurrentClient = useClient();
  const user = {
    ...CurrentClient,
    isAuthenticated:
      CurrentClient.data && !CurrentClient.isLoading && CurrentClient.isSuccess,
  };
  useInvestments();

  // NOTE: we're using this for the temp login
  useEffect(() => {
    dispatch(loadUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID || '' });
  }, []);


  if (user.isLoading)
    return <Loading />;


  // this line will prevent browser back functionality
  // only authenticate clients can see the dashboard
  if (!user.isAuthenticated)
    return LogOut();

  return (
    <FinaLayout headerContent={<HeaderItems user={user} />}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Redirect exact from="/" to={ROUTES.DASHBOARD} />

          <PrivateRoute user={user} path={ROUTES.DASHBOARD}>
            <Dashboard user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.OURSMARTFOLIOS}>
            <OurSmartfolios user={user} />
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.INVEST}>
            <Invest user={user} selectedSmartfolioName={selectedSmartfolio} />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.DEPOSIT}>
            <LeanDeposit user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.WITHDRAW}>
            <Withdraw user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.SETTINGS}>
            <Settings user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={`${ROUTES.INVESTMENT_DETAILS}/:id`}>
            <InvestmentDetails />
          </PrivateRoute>

          <Route path={ROUTES.LOGIN} component={TempLogin} />
          <Route path={ROUTES.LOGOUT} component={LogOut} />
          <Route path={ROUTES.ERROR} render={(props) => <Error {...props} />} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </FinaLayout>
  );
};

export default App;
