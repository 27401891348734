import * as React from "react";
import styled from "styled-components";
import { SIDEBAR, SIDEBAR_COLLAPSED } from "../../utils/sizes";
import { WHITE } from "../../utils/colors";
import CollapseButton from "./components/CollapseButton";
import Logo from "./components/Logo";
import Menu from "./components/Menu";

import { TRouteItem } from "../../types";

interface IFinaSidebarProps {
  collapse: boolean;
  setCollapse: (collpase: boolean) => void;
  isMobile: boolean;
  routes?: TRouteItem[];
}

const SideBar = styled.nav<{ $collapse?: boolean }>`
  background-color: ${WHITE};
  width: ${({ $collapse }) => ($collapse ? SIDEBAR_COLLAPSED : SIDEBAR)};
  position: fixed;
  transition: width 0.5s ease;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    width: ${({ $collapse }) => ($collapse ? 0 : SIDEBAR)};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BackgroundCollpase = styled.div`
  position: fixed;
  background-color: black;
  width: 100vw;
  height: 100vh;
  opacity: 0.4;
  z-index: 2;
  animation: visibility 2s linear 2s, opacity 2ss;
`;

const FinaSidebar: React.FunctionComponent<IFinaSidebarProps> = ({
  collapse,
  setCollapse,
  isMobile,
  routes,
}) => {
  return (
    <>
      {collapse === false && isMobile && (
        <BackgroundCollpase
          onClick={() => {
            if (collapse === false) setCollapse(true);
          }}
        />
      )}
      <SideBar $collapse={collapse}>
        {!isMobile ? (
          <CollapseButton
            collapse={collapse}
            setCollapse={setCollapse}
          ></CollapseButton>
        ) : null}

        <Content>
          <Logo />
          <Menu routes={routes} />
        </Content>
      </SideBar>
    </>
  );
};

export default FinaSidebar;
