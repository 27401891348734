import { combineReducers } from "redux";

import CurrUser from "./Client";
import Investements from "./Investemets";
import Positions from "./Positions";
import Filters from "./TableFilters";
import Tickers from "./Tickers";
import Products from "./Products";
import Pagination from "./TablePagination";

const rootReducer = combineReducers({
  CurrUser,
  Investements,
  Positions,
  Tickers,
  Products,
  Filters,
  Pagination,
});

export type RootStateType = ReturnType<typeof rootReducer>;
export default rootReducer;
