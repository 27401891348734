import { ReactComponent as Home } from "src/assets/layoutIcons/home.svg";
import { ReactComponent as Invest } from "src/assets/layoutIcons/invest.svg";
import { ReactComponent as Deposit } from "src/assets/layoutIcons/deposit.svg";
import { ReactComponent as Withdraw } from "src/assets/layoutIcons/withdraw.svg";
import { ReactComponent as OurSmartfolios } from "src/assets/layoutIcons/OurSmartfolios.svg";
import { TRouteItem } from "./types";

export const routes: TRouteItem[] = [
  {
    key: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: Home,
  },
  {
    key: "Smartfolios",
    path: "/smartfolios",
    name: "Smartfolios",
    icon: OurSmartfolios,
    spacer: true,
  },
  {
    key: "invest",
    path: "/invest",
    name: "Invest",
    icon: Invest,
  },
  {
    key: "deposit",
    path: "/deposit",
    name: "Deposit Cash",
    icon: Deposit,
  },
  {
    key: "withdraw",
    path: "/withdraw",
    name: "Withdraw Cash",
    icon: Withdraw,
  },
];
