import { Itheme } from "./types/theme";
import {
  FONTSIZE,
  FONTWEIGHT,
  FONTFAMILY,
  BOXSHADOW,
  BLURFILTER,
} from "./types/enums";
import { colors } from "./colors";

export const theme: Itheme = {
  colors: colors,
  typography: {
    fontSize: {
      xs: FONTSIZE.xs,
      sm: FONTSIZE.sm,
      md: FONTSIZE.md,
      lg: FONTSIZE.lg,
      xl: FONTSIZE.xl,
      "2xl": FONTSIZE["2xl"],
      "3xl": FONTSIZE["3xl"],
    },
    fontWeight: {
      regular: FONTWEIGHT.regular,
      medium: FONTWEIGHT.medium,
      bold: FONTWEIGHT.bold,
      light: FONTWEIGHT.light,
    },

    fontFamily: [FONTFAMILY.DM_Sans, FONTFAMILY.sans_serif],
  },
  boxShadow: {
    xs: BOXSHADOW.xs,
    sm: BOXSHADOW.sm,
    md: BOXSHADOW.md,
    lg: BOXSHADOW.lg,
    xl: BOXSHADOW.xl,
    "2xl": BOXSHADOW["2xl"],
    "3xl": BOXSHADOW["3xl"],
  },

  filter: {
    sm: BLURFILTER.sm,
    md: BLURFILTER.md,
    lg: BLURFILTER.lg,
    xl: BLURFILTER.xl,
  },
};
