import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

const useTransactions = () =>
  useQuery("/Transactions", () => axios(`${baseUrl}/transactions`), {
    refetchInterval: 1800000,
    refetchOnWindowFocus: false,
  });

export default useTransactions;
