import * as React from "react";
import styled from "styled-components";
import { GREY } from "../../utils/colors";
import { SIDEBAR_COLLAPSED, SIDEBAR } from "../../utils/sizes";
import * as breakpoints from "../../utils/breakpoint";
import CollapseButton from "../FinaSidebar/components/CollapseButton";

interface IFinaContentProps {
  collapse: boolean;
  setCollapse: (collpase: boolean) => void;
  isMobile: boolean;
  children?: React.ReactNode;
  headerContent?: any;
}

const Content = styled.section<{ collapse: boolean }>`
  background-color: ${GREY};
  margin-left: ${({ collapse }) => (collapse ? SIDEBAR_COLLAPSED : SIDEBAR)};
  transition: margin-left 0.5s ease;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media only screen and (max-width: ${breakpoints.MOBILE}) {
    margin-left: 0;
  }
`;

const Main = styled.div`
  flex-grow: 1;
  padding: 16px;
  display: flex;
`;

const Header = styled.header`
  height: 48px;
  background-color: white;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
`;

const FinaContent: React.FunctionComponent<IFinaContentProps> = ({
  collapse,
  setCollapse,
  isMobile,
  children,
  headerContent,
}) => {
  return (
    <Content collapse={collapse}>
      <Header>
        <div>
          {isMobile ? (
            <CollapseButton
              collapse={collapse}
              setCollapse={setCollapse}
              absolute={false}
            />
          ) : null}
        </div>
        {headerContent}
      </Header>
      <Main>{children}</Main>
    </Content>
  );
};

export default React.memo(FinaContent);
