import React from "react";
import styled, { css } from "styled-components";

import { TRouteItem } from "src/components/FinaLayout/types";

import { Link, useLocation } from "react-router-dom";
import { colors } from "src/theme/colors";

const Group = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SelectStyle = css`
  border-right: solid ${colors.primary[500]};
  background-color: #e6f7ff;
  color: ${colors.primary[500]};
  svg {
    fill: ${colors.primary[500]};
  }
`;

const Icon = styled.div`
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${colors.primary[900]};
  }
`;

const DisableStyle = css`
  cursor: not-allowed;
  opacity: 0.5;
  &:hover {
    background-color: white;
    color: ${colors.primary[900]};
    svg {
      fill: ${colors.primary[900]};
    }
  }
`;

const Item = styled(Link)<{ $select: boolean; $disable: boolean; color: string }>`
  width: 208px;
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${colors.primary[900]};
  transition: color 2 ease;
  cursor: "pointer";
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${({ $select }) => ($select ? SelectStyle : "")}
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    color: ${colors.primary[500]};
    svg {
      fill:${colors.primary[500]};
    }
  }
  ${({ $disable }) => $disable && DisableStyle}
`;

const Title = styled.span`
  white-space: nowrap;
`;

const Spacer = styled.div`
  height: 32px;
  min-height: 32px;
`;

type Props = { routes?: TRouteItem[] };

const Menu: React.FC<Props> = ({ routes }) => {
  const location = useLocation();

  return (
    <Group>
      {routes.map((r, i) => (
        <div key={`${r.name}`}>
          <Item
            to={r?.disable ? "#" : r.path}
            $select={location.pathname === r.path}
            key={`${r.name}`}
            $disable={r?.disable}
          >
            <Icon>{r.icon && <r.icon />}</Icon>
            <Title>{r.name}</Title>
          </Item>
          {r.spacer && <Spacer key={i + "spacer"} />}
        </div>
      ))}
    </Group>
  );
};

export default Menu;
