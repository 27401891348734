import React from "react";
import styled from "styled-components";
import {
  UserOutlined,
  // LogoutOutlined,
  // SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import Settings from "src/assets/navigationsIcons/settings.svg";
import Logout from "src/assets/navigationsIcons/logout.svg";

const Container = styled.span`
  display: flex;
  align-items: center;
  padding: 0px 12px;
  height: 48px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Username = styled.span`
  margin-left: 8px;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Item = styled.span`
  display: flex;
  align-items: center;
`;

type Props = {
  first: string;
  last: string;
  src: string;
};

const User: React.FC<Props> = ({ first, last, src }) => {
  const history = useHistory();
  let username = first && last ? `${first} ${last}` : "...";
  src = src
    ? `https://gateway.intg.finadevs.com/api/v1/picture/${src}`
    : undefined;

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <Link to={"/settings"}>
              <Item>
                <img src={Settings} alt="Settings" />
                <span style={{ marginLeft: "8px" }}>Settings</span>
              </Item>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={"/logout"}>
              <Item>
                <img src={Logout} alt="Logout" />
                <span style={{ marginLeft: "8px" }}>Logout</span>
              </Item>
            </Link>
          </Menu.Item>
        </Menu>
      }
    >
      <Container onClick={()=> history.push('/settings')}>
        <Avatar size="small" style={{cursor: "pointer"}} icon={<UserOutlined />} src={src} alt="Avatar" />
        <Username>{username}</Username>
      </Container>
    </Dropdown>
  );
};

export default User;
