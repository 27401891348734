import React from "react";
import styled from "styled-components";

import FinaSidebar from "./components/FinaSidebar";
import FinaContent from "./components/FinaContent";
import MobileAction from "./components/MobileAction";

import { useMediaQuery } from "./hooks/hooks";
import { GREY } from "./utils/colors";
import { IFinaLayoutProps } from "./types";
import { routes } from "./routes";

const Layout = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background-color: ${GREY};
`;

const FinaLayout: React.FC<IFinaLayoutProps> = ({
  headerContent,
  children,
}) => {
  const mobile = useMediaQuery("(max-width: 767px)");
  const laptop = useMediaQuery("(min-width: 767px) and (max-width: 992px)");
  const [collapse, setCollapse] = React.useState<boolean>(
    !mobile && !laptop ? false : true,
  );
  React.useEffect(() => {
    if (mobile && collapse !== mobile) setCollapse(mobile);
    else if (laptop && collapse !== laptop) setCollapse(laptop);
    else if (!mobile && !laptop && collapse !== false) setCollapse(false);
    // eslint-disable-next-line
  }, [mobile, laptop]);

  return (
    <Layout>
      <FinaSidebar
        collapse={collapse}
        setCollapse={setCollapse}
        isMobile={mobile}
        routes={routes}
      />
      <FinaContent
        collapse={collapse}
        setCollapse={setCollapse}
        isMobile={mobile}
        headerContent={headerContent}
      >
        {children}
      </FinaContent>
      <MobileAction />
    </Layout>
  );
};

export default FinaLayout;
