import React from "react";
import styled from "styled-components";
import { ReactComponent as FinamazeLogo } from "src/assets/logo.svg";
import { WEBAPPLINK } from "src/utils/config";

const Container = styled.a`
  height: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const Logo: React.FC = () => {
  return (
    <Container>
      <FinamazeLogo />
    </Container>
  );
};

export default Logo;
