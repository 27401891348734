import React from "react";
import styled from "styled-components";
import { WEBAPPLINK } from "src/utils/config";
import { Tooltip } from "antd";
import MailIcon from "src/assets/ContactUs.svg";

const Container = styled.span`
  display: flex;
  align-items: center;
  padding: 0px 12px;
  height: 48px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const ContactUs: React.FC = () => {
  return (
    <Container
      onClick={() => {
        window.open(`${WEBAPPLINK}/contact-us`, "_blank");
      }}
    >
      <Tooltip title="Contact us">
        <img src={MailIcon} alt="contact us" />
      </Tooltip>
    </Container>
  );
};

export default ContactUs;
