export const LARGESCREEN = 1367;
export const DESKTOP = 1225;
export const SMALLLAPTOP = 1025;
export const IPAD = 769;
export const MOBILE = 425;

export const DASHBOARD_BREAKPT_1 = 1200;
export const DASHBOARD_BREAKPT_2 = 1600;

export const antd_breakingPts = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
