import { useQuery } from "react-query";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

const useInvestments = () => {
  const res = useQuery(
    "/Investments/count",
    async () =>
      (
        await axios.request({
          url: `${baseUrl}/investments/count`,
          method: "get",
          params: {
            status: "ACTIVE,PENDING_EXIT,ACTIVE_EXIT",
          },
        })
      ).data,
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
    },
  );
  const { data, ...rest } = res;
  return {
    countData: data,
    ...rest,
  };
};

export default useInvestments;
