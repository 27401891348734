import React from "react";
import { userInterface } from "src/interfaces/Client";
import ContactUs from "./components/ContactUs";
import User from "./components/User";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  user: userInterface;
};

const HeaderItem: React.FC<Props> = ({ user }) => {
  return (
    <Container>
      <ContactUs />
      <User
        first={user?.data?.name.first}
        last={user?.data?.name.last}
        src={user?.data?.picture}
      />
    </Container>
  );
};

export default HeaderItem;
