import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import api from "./middleware/api";

// It's advised to use the getdefault middleware like this, soo keep it!
const MiddlwaresArray = (() => getDefaultMiddleware().concat(api))();

const store = configureStore({
  reducer: rootReducer,
  middleware: MiddlwaresArray,
});

export type AppDispatchType = typeof store.dispatch;

export default store;
