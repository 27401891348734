import { Middleware } from "redux";
import axiosInstance from "src/utils/axiosInstance";
import { PayloadAction } from "@reduxjs/toolkit";

import * as ApiActions from "../ApiActions";
import { ClientRequestInterface } from "src/interfaces/StoreApi";
import { RootStateType } from "../rootReducer";
/*
 *******************************************************************************
 ** NOTE this middleware handles all the fetch requests,
 ** that needs to add data to the store
 */

const api: Middleware<{}, RootStateType> =
  ({ dispatch }) =>
  (next) =>
  async (action: PayloadAction<ClientRequestInterface>) => {
    if (action.type !== ApiActions.apiCallBegan.type) return next(action);

    const {
      url,   
      method = "get",
      onStart,
      onSuccess,
      onFail,
      params = "",
    } = action.payload;

    next(action);
    dispatch({ type: onStart });
    try {
      const response = await axiosInstance.request({
        method,
        url,
        params: params,
      });
      if (onSuccess) {
        dispatch({ type: onSuccess, payload: response.data });
      }
      dispatch(ApiActions.apiCallSuccess());
    } catch (err) {
      dispatch(ApiActions.apiCallFailed(err));
      if (onFail) dispatch({ type: onFail });
    }
  };
export default api;
