import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "sanitize.css";
import "antd/dist/antd.css";
import "@ant-design/pro-layout/dist/layout.css";
import "@ant-design/pro-card/dist/card.css";
import "@ant-design/pro-table/dist/table.css";
import "./index.css";
import App from "./App";
import store from "src/store/store";
import AutoLogout from "./components/AutoLogout";
import StyleProvider from "./theme/StyleProvider";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
      <Router>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <StyleProvider>
              <App />
            </StyleProvider>
            <AutoLogout timeout={600} />
          </QueryClientProvider>
        </Provider>
      </Router>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
