import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import { clientRemoved } from "src/store/Client";
import { investementsRemoved } from "src/store/Investemets";
import { positionsRemoved } from "src/store/Positions";
import { domainName, WEBAPPLINK } from "src/utils/config";

const LogOut = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  dispatch({ type: clientRemoved.type });
  dispatch({ type: investementsRemoved.type });
  dispatch({ type: positionsRemoved.type });
  cookies.remove("JWToken", { domain: domainName });
  cookies.remove("_expiredTime", { domain: domainName });

  useEffect(() => {
    window.location.replace(WEBAPPLINK);
  }, []);

  return null;
};

export default LogOut;
